#__tealiumGDPRecModal {
  .privacy_prompt1 {
    background-color: rgba(1, 51, 101, 0.9) !important;
    padding: 10px 1% !important;
    @media only screen and (max-width: 768px) {
      padding: 16px !important;
    }
  }

  .privacy_prompt_container1 {
    overflow-y: unset !important;
    width: 100vw;
    font-family:  'Poppins',NeueHaas, 'Helvetica Neue', Arial, sans-serif !important;
    display: grid;
    padding: 10px 64px;
    @media only screen and (min-width: 769px) {
      grid-template-columns: 70% auto;
    }
    @media only screen and (max-width: 1024px) {
      padding: 0 32px;
    }
    .privacy_prompt_content1 {
      font-size: 14px;
      @media only screen and (max-width: 1024px) {
        padding: 0 32px;
      }
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
    }
    .privacy_prompt_content21 {
      align-items: end;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 10px;

      @media only screen and (min-width: 769px) {
        margin-top: unset;
        padding-left: 25%;
      }
      #consent_prompt_submitNo.privacy_prompt_content2,
      #consent_prompt_submit.privacy_prompt_content2,
      #consent_prompt_pref {
        margin: 0;
        width: 221px;
        height: 40px;
        font-size: 14px ;
        font-weight: 600;
        padding: 0.68rem 0;
        border-radius: 50vmax;
        text-decoration: none;
        @media only screen and (max-width: 768px) {
          width: 100% ;
          margin: 0 auto ;
        }
      }

      #consent_prompt_submitNo.privacy_prompt_content2,
      #consent_prompt_submit.privacy_prompt_content2 {
        background-color: #005abb;
        border: .4px solid #333;
        color:#c8e1f6;
      }

      #consent_prompt_pref {
        display: flex;
        flex-direction: row;
        align-items: center;
        a.privacy_prompt_content2 {
          text-decoration: inherit;
          display: block;
          color: #c8e1f6;
          background-color: transparent;
          border: 0.4px solid #c8e1f6;
          width: inherit;
          height: inherit;
          padding: .68rem;
          border-radius: inherit;
          font-size: inherit;
        }
      }
    }

  }

  .privacy_prompt_container1 .__button {
    border:10px solid red;
    border-radius: 50vmax;
    font-size: 12px !important;
    height: 40px;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}
.lazy-load-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100vw;

  img {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: cover;
    vertical-align: bottom;
  }
}

span.lazy-load-image-background {
  font-size: 0;
}

.pac-container {
  z-index: 1201;
}
